import './src/styles/global.scss';

// @ts-ignore
if ('serviceWorker' in navigator) {
}

export const onServiceWorkerUpdateFound = () => {
    console.log('onServiceWorkerUpdateFound');
};

export const onServiceWorkerUpdateReady = () => {
    console.log('onServiceWorkerUpdateReady');
    // @ts-ignore
    window.location.reload(true);
};
